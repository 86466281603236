<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Scheduling" backTo="service">
      <router-link
          :to="{ name: 'schedule-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14"/>
        </svg>
        <span>New Job</span>
      </router-link>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-0 mr-2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected"/>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <div class="flex items-center mr-4">
                <label class="mr-2">From Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="fromDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <div class="flex items-center ">
                <label class="mr-2">To Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="toDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>

              <!--              <StaffAutocomplete class="ml-6 w-full lg:w-1/5 xl:w-1/5 2xl:w-1/5" v-model="selectedEngineerInput"-->
              <!--                                 :forceSelection="true"/>-->

              <input
                  type="text"
                  class="ml-6 w-full lg:w-auto rounded-l bge-input bge-input-spacing"
                  placeholder="Search "
                  v-model="searchTextInput"
                  @keydown.enter="triggerSearch"
              />
              <button
                  @click="triggerSearch"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <Icon iconType="search"/>
              </button>
            </div>
            <!--            <div class="flex justify-end mt-5">-->
            <!--              <StaffAutocomplete class="mr-8 px-2 w-full lg:w-1/4 "/>-->
            <!--            </div>-->
          </div>
        </div>

        <!-- Tab Content -->

        <TabContent :currentTab="currentTab">
          <template v-if="currentTab === 'my_schedule'" v-slot:my_schedule>
            <DataTableComponent :columns="my_schedule_columns" @row-click="onRowClick" rowGroupMode="rowspan"
                                groupRowsBy="schedule_date" key="my_schedule"
                                :enableRowClick=false>

              <template v-slot:schedule-date-slot="{ rowData }">
                {{ rowData.schedule_date }}
              </template>

              <template v-slot:schedule-day-slot="{ rowData }">
                {{ rowData.schedule_day }}
              </template>

              <template v-slot:timestamp-slot="{ rowData }">

                {{ formatTimestamp(rowData.calibration_next_date) }}
              </template>

              <template v-slot:owner-postcode-slot="{ rowData }">
                <div v-if="rowData.owner_info.addresses">
                  {{ getDefaultAddressPostcode(rowData.owner_info.addresses) }}
                </div>
              </template>

              <template v-slot:owner-phone-slot="{ rowData }">
                <div v-if="rowData.owner_info.people">
                  {{ getDefaultPeoplePhone(rowData.owner_info.people) }}
                </div>
              </template>

              <template v-slot:engineer-name-slot="{ rowData }">
            <span v-if="rowData.active_schedule && rowData.active_schedule.engineer">
            {{ rowData.active_schedule.engineer.first_name }} {{ rowData.active_schedule.engineer.last_name }}
           </span>
              </template>

              <template v-slot:scheduled-slot="{ rowData }">
                <span v-if="rowData.active_schedule_id">{{ 'Yes' }}</span>
                <span v-else>{{ 'No' }}</span>
              </template>
              <template v-slot:job-id-slot="{ rowData }">
                <router-link
                    class="text-blue-600 hover:text-blue-800 underline cursor-pointer"
                    :to="{ name: 'engineer-schedule-view', params: { job_id: rowData.active_schedule.job_id }}"
                >
                  {{ rowData.active_schedule.job_id }}
                </router-link>
              </template>

            </DataTableComponent>
          </template>

          <!-- Default slot for other tabs -->
          <template v-else v-slot:[currentTab]>
            <DataTableComponent :columns="columns" @row-click="onRowClick" key="schedule" :rowClass="rowClass">
              <template v-slot:timestamp-slot="{ rowData }">
                {{ formatTimestamp(rowData.calibration_next_date) }}
              </template>

              <template v-slot:owner-postcode-slot="{ rowData }">
                <div v-if="rowData.owner_info.addresses">
                  {{ getDefaultAddressPostcode(rowData.owner_info.addresses) }}
                </div>
              </template>

              <template v-slot:engineer-name-slot="{ rowData }">
            <span v-if="rowData.active_schedule && rowData.active_schedule.engineer">
            {{ rowData.active_schedule.engineer.first_name }} {{ rowData.active_schedule.engineer.last_name }}
           </span>
              </template>

              <template v-slot:scheduled-slot="{ rowData }">
                <span v-if="rowData.active_schedule_id">{{ 'Yes' }}</span>
                <span v-else>{{ 'No' }}</span>
              </template>

            </DataTableComponent>
          </template>
        </TabContent>


      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/HR/Engineer/Schedule/Components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";

const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  mixins: [notificationMixin],
  components: {
    StaffAutocomplete,
    PageHeader,
    Spinner,
    Panel,
    IsAuthorized,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
  },
  data() {
    return {
      tabs: [
        {
          id: "all_expiring",
          label: "All Expiring",

        },
        {id: "scheduled", label: "Scheduled",},
        {id: "unscheduled", label: "Unscheduled",},
      ],
      columns: [
        {
          field: "owner_info.name",
          header: "Owner Name",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "owner_info.addresses[0].postcode",
          header: "Postcode",
          sortable: false,
          custom: true,
          slotName: "owner-postcode-slot",
          width: '8%',
        },
        {
          field: "product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '8%',
        },
        {
          field: "name",
          header: "Description",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "serial_number",
          header: "Serial Number",
          sortable: true,
          custom: false,
          width: '12%',
        },
        {
          field: "calibration_next_date",
          header: "Expiry",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot",
          width: '10%',
        },
        {
          field: "active_schedule.engineer",
          header: "Engineer Name",
          sortable: false,
          custom: true,
          slotName: "engineer-name-slot",
          width: '15%',
        },
        {
          field: "active_schedule_id",
          header: "Scheduled",
          sortable: false,
          custom: true,
          slotName: "scheduled-slot",
          width: '8%',
        },
        {
          field: "active_schedule.job_id",
          header: "Job ID",
          sortable: true,
          custom: false,
          width: '9%',
        },
      ],
      my_schedule_columns: [
        {
          field: "schedule_date",
          header: "Day",
          sortable: false,
          custom: true,
          slotName: "schedule-day-slot",
          width: '10%',
        },
        {
          field: "",
          header: "Date",
          custom: true,
          slotName: "schedule-date-slot",
          width: '8%',
        },
        {
          field: "schedule_time",
          header: "Time",
          sortable: false,
          custom: false,
          width: '8%',
        },
        {
          field: "owner_info.name",
          header: "Customer",
          sortable: false,
          custom: false,
          width: '18%',
        },
        {
          field: "owner_info.addresses[0].postcode",
          header: "Postcode",
          sortable: false,
          custom: true,
          slotName: "owner-postcode-slot",
          width: '8%',
        },
        {
          field: "owner_info.people[0].telephone",
          header: "Telephone",
          sortable: false,
          custom: true,
          slotName: "owner-phone-slot",
          width: '10%',
        },
        {
          field: "product_code",
          header: "Code",
          sortable: false,
          custom: false,
          width: '8%',
        },
        {
          field: "name",
          header: "Equipment",
          sortable: false,
          custom: false,
          width: '15%',
        },

        {
          field: "active_schedule.engineer",
          header: "Engineer",
          sortable: false,
          custom: true,
          slotName: "engineer-name-slot",
          width: '10%',
        },
        {
          field: "active_schedule.job_id",
          header: "Job ID",
          sortable: false,
          custom: true,
          slotName: "job-id-slot",
          width: '5%',
        },
      ],
    };
  },
  computed: {
    ...mapState("schedule", ["currentTab", "searchText", "selectedEngineer", "searchDate"]),

    ...mapState("schedule", {
      currentTabState: (state) => state.tabs[state.currentTab],
    }),

    searchTextInput: {
      get() {
        return this.currentTabState.searchText;
      },
      set(value) {
        this.$store.commit("schedule/UPDATE_SEARCH_TEXT", {tab: this.currentTab, searchText: value});
      },
    },

    selectedEngineerInput: {
      get() {
        return this.currentTabState.selectedEngineer;
      },
      set(value) {
        this.$store.commit("schedule/UPDATE_SELECTED_ENGINEER", {tab: this.currentTab, searchText: value});
      },
    },

    fromDateInput: {
      get() {

        const fromDate = this.currentTabState.fromDate;
        return fromDate ? new Date(fromDate) : null;
      },
      set(value) {

        this.$store.commit("schedule/UPDATE_FROM_DATE", {tab: this.currentTab, fromDate: value});
      }
    },

    toDateInput: {
      get() {
        const toDate = this.currentTabState.toDate;
        return toDate ? new Date(toDate) : null;
      },
      set(value) {
        this.$store.commit("schedule/UPDATE_TO_DATE", {tab: this.currentTab, toDate: value});
      }
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("schedule", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
    ]),

    rowClass(data) {
debugger;
      const now = this.$moment().unix();
      const twoWeeksAhead = this.$moment().add(2, 'weeks').unix();

      if (data.active_schedule_id) {
        return 'row-scheduled';
      } else {

        if ((data.calibration_next_date && data.calibration_next_date < twoWeeksAhead) ||
            (data.next_service && data.next_service < twoWeeksAhead)) {
          return 'row-warning';
        }

        if ((data.calibration_next_date && data.calibration_next_date < now) ||
            (data.next_service && data.next_service < now)) {
          return 'row-danger';
        }
      }
      return '';
    },


    getDefaultAddressPostcode(addressArray) {
      const defaultAddress = addressArray.find(address => address.is_default);
      return defaultAddress ? defaultAddress.postcode : 'N/A';
    },
    getDefaultPeoplePhone(peopleArray) {

      const defaultPhone = peopleArray.find(people => people.is_default);
      return defaultPhone ? defaultPhone.mobile || defaultPhone.telephone : 'N/A';
    },
    onRowClick(event) {

      this.Edit(event.data);
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    onTabSelected(newTab) {
      this.setTab(newTab);
    },
    setTab(newTab) {
      this.$store.dispatch("schedule/updateTab", newTab);
    },
    triggerSearch() {
      this.setTab(this.currentTab);
    },

    Edit(rowObj) {

      let routeName;
      let params = {};

      if (rowObj.active_schedule_id) {
        routeName = "schedule-edit";
        params.schedule_id = rowObj.active_schedule_id;
      } else {
        routeName = "schedule-create";
        if (rowObj.owner_info && rowObj.owner_info.contact_id) {

          this.$router.push({
            name: routeName,
            query: {customer_id: rowObj.owner_info.contact_id}
          });
          return;
        }
      }

      this.$router.push({
        name: routeName,
        params: params,
      });
    },


  },
};
</script>

<style scoped>

::v-deep .row-scheduled {
  background-color: lightgreen !important;
}

::v-deep .row-warning {
  color: orange !important;
}

::v-deep .row-danger {
  color: red !important;
}
</style>

